/* Preloader.css */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #1a1a1a, #4d4d4d); /* Dark gradient background */
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top */
    overflow: hidden; /* Prevent overflow */
}

.bubble {
    position: absolute;
    bottom: -50px; /* Start below the screen */
    background: rgba(255, 255, 255, 0.5); /* White bubbles */
    border-radius: 50%; /* Make it circular */
    animation: rise 5s ease-in infinite; /* Rise animation */
}

/* Logo styling */
.logoo {
    width: 120px; /* Adjust size as needed */
    height: 120px; /* Maintain a square to make it circular */
    border-radius: 50%; /* Make it circular */
    object-fit: cover; /* Ensures the logo fits well within the circle */
    position: relative; /* Position for any potential effects */
    z-index: 1; /* Place it above bubbles and particles */
    animation: bounce-towards 2s infinite; /* Add bounce animation */
}

/* Bubble rise animation */
@keyframes rise {
    0% {
        transform: translateY(0);
        opacity: 1; /* Fully visible */
    }
    100% {
        transform: translateY(-100vh); /* Move upward */
        opacity: 0; /* Fade out */
    }
}

/* Add 3D bounce towards animation */
@keyframes bounce-towards {
    0%, 100% {
        transform: translateZ(0) scale(1);
    }
    20% {
        transform: translateZ(-10px) scale(1.1); /* Move slightly towards the viewer */
    }
    40% {
        transform: translateZ(0) scale(1); /* Back to original position */
    }
    60% {
        transform: translateZ(-5px) scale(1.05); /* Slightly closer */
    }
    80% {
        transform: translateZ(0) scale(1); /* Back to original position */
    }
}
