/* Flexbox Layout to push footer to the bottom */
body, html {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #000, #333);
    color: #fff;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: scroll; /* Allow vertical scrolling */
    scrollbar-width: none; /* For Firefox */
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
body::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
}


main {
    flex-grow: 1; /* This ensures the main content takes up available space */
    width: 100%;
}

.container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

/* Navbar Styles */
.navbar {
    background-color: #333;
    padding: 15px;
    color: #fff;
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar .logo {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
}

.logo-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.logo:hover {
    text-decoration: underline;
}

.navbar .nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navbar .nav-links li {
    display: inline-block;
    margin-left: 20px;
}

.navbar .nav-links li a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.navbar .nav-links li a:hover {
    text-decoration: underline;
}

/* Responsive Navbar */
@media (max-width: 768px) {
    .navbar .container {
        flex-direction: column;
        align-items: flex-start;
    }
    .navbar .nav-links {
        margin-top: 10px;
    }
}

/* Particle background setup */
#particles-js {
    position: absolute; /* Change to absolute to avoid scrolling issues */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    overflow: hidden; /* Prevent overflow */
}

/* Snowflake particle styles */
.floating-particle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url('./images/snowflakes.png'); /* Ensure this path is correct */
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    animation: float 30s linear infinite;
}

@keyframes float {
    0% {
        transform: translateY(0) translateX(0) scale(1);
        opacity: 0.8;
    }
    50% {
        transform: translateY(-200px) translateX(100px) scale(1.2);
        opacity: 0.5;
    }
    100% {
        transform: translateY(300px) translateX(-100px) scale(0.8);
        opacity: 0.2;
    }
}


/* Footer Styles */
footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 20px;
}

.footer-section {
    flex-basis: 45%;
}

.footer-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.social-icons {
    list-style: none;
    padding: 25px;
    display: flex;
    gap: 15px;
    justify-content: center;
    position: relative;
    bottom: 25px;
    left: 0px;
}

.social-icons li {
    display: inline-block;
}

.social-icons img {
    width: 30px;
    height: auto;
}

.footer-links {
    list-style: none;
    padding: 0;
}

.footer-links li {
    margin: 5px 0;
}

.footer-links a {
    color: #fff;
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-bottom {
    border-top: 1px solid #555;
    padding-top: 10px;
}

.footer-bottom a {
    color: #4a90e2;
    text-decoration: none;
}

.footer-bottom a:hover {
    text-decoration: underline;
}

.coming-soon {
    position: relative;
    pointer-events: none; /* Make it non-clickable */
}

.coming-soon .overlay-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, rgba(255, 0, 0, 0.8), rgba(128, 128, 128, 0.8)); /* Red to grey gradient */
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    z-index: 2;
    opacity: 0.9; /* Adjusted for better visibility */
    pointer-events: none; /* Prevent interaction with the overlay */
    
    /* Create a cool shape using clip-path */
    clip-path: polygon(0 0, 100% 0, 100% 60%, 50% 100%, 0 60%);
}

.coming-soon img.big-chicken-logo {
    filter: grayscale(100%); /* Make the image appear grayed out */
    opacity: 0.5;
}

/* Leaderboard Overview Section */
/* Leaderboard Overview Section */
/* Leaderboard Overview Section */
/* Leaderboard Overview Section */
.leaderboard-overview {
    text-align: center;
    padding: 50px 0;
}

.leaderboard-overview h2 {
    font-size: 36px;
    color: #fff;
    margin-bottom: 20px;
}

.leaderboard-score {
    display: flex;
    justify-content: space-around; /* Closer alignment */
    align-items: center;
    width: 70%; /* Reduce width to bring sections closer */
    margin: 0 auto;
}

/* Score containers */
.left-score, .middle-score, .right-score {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.big-chicken-logo {
    width: 100%;
    max-width: 250px;
    height: auto;
    margin-bottom: 10px;
}

.score-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.coin-icon {
    width: 35px;
    height: 35px;
    margin-right: 5px; /* Reduce margin between coin and score */
    vertical-align: middle;
}

.score-text {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    vertical-align: middle;
}

/* Dividers */
.divider-left, .divider-middle, .divider-right {
    width: 2px;
    height: 100px;
    background: linear-gradient(to bottom, #888, #fff);
}

.divider-left {
    margin-right: 10px; /* Reduce margin between sections */
}

.divider-middle {
    margin-left: 10px; /* Bring sections closer */
    margin-right: 10px;
}

.divider-right {
    margin-left: 10px;
}

/* Game Cards Section */
.game-cards {
    padding: 50px 0;
}

.game-cards h2 {
    padding-left: 720px;
    color: #fff;
    font-size: 36px;
    margin-bottom: 45px;
    text-align: left;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 15px;
    padding: 0;
    margin: 0;
}

.card {
    background: linear-gradient(to bottom, rgba(128, 128, 128, 0.7), rgba(0, 0, 0, 0.7));
    width: calc(25% - 15px);
    margin: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: #fff;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; 
    overflow: hidden;
    position: relative;
    transform-origin: center; /* Set the scaling origin to the center */
}

@media (max-width: 1024px) {
    .card {
        width: calc(33.33% - 15px);
    }
}

@media (max-width: 768px) {
    .cards-container {
        flex-direction: column;
    }
    .card {
        width: 100%;
    }
}

.card img {
    width: 100%;
    height: 130px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
}

.card-content {
    padding: 20px;
    padding-top: 160px;
    text-align: center;
    position: relative;
    z-index: 2;
}

.card-content h3 {
    margin-top: 0;
    font-size: 22px;
    text-align: center;
}

.card-content p {
    color: #ddd;
    font-size: 14px;
    text-align: center;
}

.gradient-text {
    font-size: 35px;
    font-weight: bold;
    background: linear-gradient(90deg, #4a90e2, #7b61ff, #b388ff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px solid;
    border-image: linear-gradient(90deg, #4a90e2, #7b61ff, #b388ff) 1;
    padding: 5px;
    display: inline-block;
    text-align: center;
    margin-top: -10px;
}

.card:hover {
    transform: scale(1.02); /* Adjusted scale for subtle effect */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8), 0 0 20px rgba(0, 0, 255, 0.5); 
    border: 2px solid transparent; /* Keeps layout stable */
    border-image: linear-gradient(90deg, #4a90e2, #7b61ff, #b388ff) 1;
}

.play-button {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 80px;
    background-color: rgb(49, 121, 255);
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
}

.play-button:hover {
    background-color: rgb(70, 70, 70);
}

/* Social Media Cards Section */
.social-media-cards {
    padding: 50px 0;
    text-align: center;
}

.social-media-cards h2 {
    font-size: 36px;
    color: #fff;
    margin-bottom: 30px;
}

.social-media-cards .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.social-media-cards .card {
    background: rgba(255, 255, 255, 0.1);
    width: 250px;
    margin: 10px;
    padding: 30px 15px 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social-media-cards .card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.social-media-cards .card img {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.social-media-cards .card h3 {
    font-size: 20px;
    color: #fff;
    margin-top: 50px;
}

.social-media-cards .card p {
    font-size: 14px;
    color: #ddd;
    margin-bottom: 15px;
}

.social-media-cards .play-button {
    padding: 10px 20px;
    background-color: rgb(49, 121, 255);
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.social-media-cards .play-button:hover {
    background-color: rgb(70, 70, 70);
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 15px;
    padding: 0;
    margin: 0;
    overflow: hidden; /* Prevent overflow from scaling cards */
}


/*YT VIDEO EMBED HOME*/

/* Add this to your styles.css */
.ytalign-left {
    text-align: left;
    margin-left: 45px; /* Adjust as needed */
}

.ytalign-right {
    text-align: right;
    margin-right: 0; /* Adjust as needed */
}

.youtube-videos {
    margin: 2rem 0;
}

.video-container {
    display: flex;
    justify-content: space-around; /* Space out the videos */
    flex-wrap: wrap; /* Wrap to the next line on smaller screens */
}

.video-embed {
    width: 550px; /* Set width for the video */
    height: 300px; /* Set height for the video (16:9 aspect ratio) */
    border: none; /* Remove border */
    margin: 1rem; /* Margin around each video */
}


/* Responsive adjustments for smaller screens */
@media (max-width: 600px) {
    .game-cards h2, .social-media-cards h2, .leaderboard-overview h2 {
        padding-left: 0;
        text-align: center;
    }
    .cards-container {
        flex-direction: column;
        align-items: center;
    }
    .card {
        width: 90%;
        margin-bottom: 20px;
    }
}

/* Leaderboard Styles */
.leaderboard-section {
    padding: 50px 0;
    text-align: center;
}

.leaderboard-section h1 {
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: bold;
    color: #333;
}

.countdown-timer {
    font-size: 24px; /* Adjust the size as needed */
    background: linear-gradient(to right, #ff0000, #ff7f7f); /* Red gradient */
    -webkit-background-clip: text; /* For Safari */
    -webkit-text-fill-color: transparent; /* Makes the text transparent to show the gradient */
    background-clip: text; /* For other browsers */
}
.podium-profile-pic {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    border-radius: 50%; /* Makes the image round */
    margin: 0 auto 10px; /* Centers the image above the rank */
}

.table-profile-pic {
    width: 40px; /* Set a fixed width */
    height: 40px; /* Set a fixed height */
    border-radius: 50%; /* Make the image circular */
    margin-right: 10px; /* Space between the image and username */
}


/* Podium Styles */
.podium-container {
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Align items to the bottom */
    margin-bottom: 30px; /* Space below the podium */
    position: relative; /* Position relative for absolute positioning of reward images */
}

.podium-spot {
    background: linear-gradient(145deg, #f9f9f9, #e2e2e2);
    border-radius: 20px; /* Rounded corners */
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2), 
                -5px -5px 15px rgba(255, 255, 255, 0.8); /* Cool shadow effect */
    position: relative;
    margin: 0 20px; /* Spacing between podium spots */
    padding: 20px; /* Space inside the podium */
    text-align: center; /* Center align text */
    transition: transform 0.3s ease; /* Animation on hover */
}

.podium-spot:hover {
    transform: translateY(-10px); /* Slight lift effect on hover */
}

.podium-spot.gold {
    background: linear-gradient(145deg, #f5d300, #d4af37); /* Gold gradient */
}

.podium-spot.silver {
    background: linear-gradient(145deg, #c0c0c0, #e0e0e0); /* Silver gradient */
}

.podium-spot.bronze {
    background: linear-gradient(145deg, #cd7f32, #f4a460); /* Bronze gradient */
}

/* Podium content styles */
.podium-rank {
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
}

.podium-rank,
.podium-username,
.podium-wagered,
.podium-reward {
    margin-top: 10px; /* Space above the text */
    font-weight: bold; /* Make text bold */
    color: #333; /* Dark text color */
}

/* Reward image styling */
.reward-image {
    width: 100px; /* Increase the size of the images */
    height: auto; /* Maintain aspect ratio */
    position: absolute;
    bottom: -50px; /* Adjust this value to position the images lower */
    left: 50%; /* Center the image horizontally */
    transform: translateX(-50%); /* Center the image based on its own width */
    z-index: 1; /* Ensure images are above the podium */
}

/* Leaderboard Table Styles */
/* Leaderboard Table Styles */
.leaderboard-table {
    margin-top: 20px; /* Space above the leaderboard */
    border-collapse: collapse; /* Collapse table borders */
    width: 100%; /* Full width */
}

.leaderboard-header {
    background-color: #ff0000;
    font-weight: bold; /* Make header bold */
    display: flex; /* Use flexbox for header */
}

.leaderboard-row {
    position: relative; /* Set position relative for pseudo-element positioning */
    transition: transform 0.3s ease; /* Smooth scaling effect */
}

.leaderboard-row:hover {
    transform: scale(1.1); /* Scale up on hover */
}

.leaderboard-row::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 5px; /* Optional: rounded corners */
    background: linear-gradient(to right, gold, orange);
    z-index: -1; /* Send it behind the row content */
    transition: opacity 0.3s ease; /* Smooth transition for the outline */
    opacity: 0; /* Initially hidden */
}

.leaderboard-row:hover::before {
    opacity: 1; /* Show outline on hover */
}

.wagereds {
    background: linear-gradient(to right, gold, orange);
    -webkit-background-clip: text; /* For Safari */
    background-clip: text; /* For other browsers */
    color: transparent; /* Text is transparent to show gradient */
    font-weight: bold; /* Makes the text bolder */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and scaling */
    display: inline-block; /* Make the container inline-block for scaling */
    padding: 10px; /* Add padding to increase hover area */
    cursor: pointer; /* Change cursor to pointer for better UX */
}

/* This hover rule applies to the entire container */
.wagereds:hover {
    color: black; /* Change text color to black on hover */
    transform: scale(1.1); /* Scale the container on hover */
}

/* Ensure the gradient effect is removed on hover */
.wagereds:hover {
    background: none; /* Remove the gradient to show the black text */
    color: black; /* Text color changes to black */
}





.leaderboard-header .rank,
.leaderboard-header .username,
.leaderboard-header .wagered,
.leaderboard-header .reward {
    flex: 1; /* Make all columns take equal space */
    text-align: center; /* Center align text in header */
}

.leaderboard-row .rank,
.leaderboard-row .username,
.leaderboard-row .wagered,
.leaderboard-row .reward {
    flex: 1; /* Make all columns take equal space */
    text-align: center; /* Center align text in rows */
}

.leaderboard-row .rank {
    font-size: 20px; /* Slightly larger font for rank */
    font-weight: bold;
}

.leaderboard-row .username,
.leaderboard-row .wagered,
.leaderboard-row .reward {
    font-size: 16px; /* Consistent font size for row data */
    color: #555; /* Slightly darker for better visibility */
}

/* Username styling to align profile picture */
.username {
    display: flex; /* Use flexbox for the username container */
    align-items: center; /* Center align items vertically */
}

/* Overlay Image Styles */
.overlay {
    position: absolute; /* Position it absolutely within the nearest positioned ancestor */
    top: 0%; /* Adjust top positioning as needed */
    left: 1050px; /* Align to the right */
    width: 500px; /* Set the desired width */
    height: auto; /* Maintain aspect ratio */
    z-index: 10; /* Ensure it is above other content */
}

.overlay img {
    width: 100%; /* Make image responsive to the container */
    height: auto; /* Maintain aspect ratio */
}

.overlayd {
    position: absolute; /* Position it absolutely within the nearest positioned ancestor */
    top: -18%; /* Adjust top positioning as needed */
    right: 975px; /* Align to the right */
    width: 550px; /* Set the desired width */
    height: auto; /* Maintain aspect ratio */
    z-index: 10; /* Ensure it is above other content */
}

.overlayd img {
    width: 100%; /* Make image responsive to the container */
    height: auto; /* Maintain aspect ratio */
}

/* styles.css */

/* styles.css */

/* styles.css */

/* styles.css */

/* styles.css */

/* styles.css */

/* styles.css */

/* styles.css */

/* styles.css */

/* styles.css */

/* styles.css */

.floating-image {
    position: absolute; /* Keep absolute positioning */
    right: 20px; /* Position from the right */
    top: 100px; /* Position from the top */
    z-index: 10; /* Ensure it’s above other content */
}

/* Keyframes for floating animation */
@keyframes subtle-float {
    0%, 100% {
        transform: translateY(0); /* Start and end at the original position */
    }
    50% {
        transform: translateY(-10px); /* Move up 10px at the halfway point */
    }
}

/* Apply the animation */
.subtle-floating {
    animation: subtle-float 4s ease-in-out infinite; /* Apply the animation */
}


.leaderboard-page {
    min-height: 100vh; /* Full viewport height */
    background: linear-gradient(to bottom, #333, #000);
    background-image: radial-gradient(circle at left, rgba(255, 255, 0, 0.1), transparent),
                      radial-gradient(circle at right, rgba(255, 255, 0, 0.1), transparent);
    color: #fff;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}


/* styles.css */

.nav-item {
    cursor: pointer; /* Change cursor to pointer on hover */
    color: #fff; /* White color for text */
    transition: color 0.3s ease; /* Smooth transition for color change */
}

/* Optional: Add hover effect */
.nav-item:hover {
    color: #ffcc00; /* Change color on hover (choose a color you like) */
}

/* styles.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure modal is above other content */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.admin-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
}

.logo-item {
    flex: 1;
    position: relative;
    background: rgba(255, 127, 80, 0.5); /* Transparent background */
    border-radius: 5px;
    padding: 10px;
    margin-right: 0; /* No margin for connection */
}

.gradient-black { 
    position: absolute; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    height: 50%; 
    background: linear-gradient(to top, black, transparent);
}

.gradient-pink { 
    position: absolute; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    height: 50%; 
    background: linear-gradient(to top, #ff007f, transparent);
}

.gradient-navy { 
    position: absolute; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    height: 50%; 
    background: linear-gradient(to top, navy, transparent);
}

.gradient-gold { 
    position: absolute; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    height: 50%; 
    background: linear-gradient(to top, gold, transparent);
}

.gradient-light-gold { 
    position: absolute; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    height: 50%; 
    background: linear-gradient(to top, #ffd700, transparent);
}




.footer-credits {
    text-align: center; /* Center align the text */
    margin-top: 10px; /* Space above the credits section */
}

.footer-credits a {
    color: #ffcc00; /* Yellow color for the name */
    text-decoration: none; /* Remove underline */
}

.footer-credits a:hover {
    text-decoration: underline; /* Underline on hover */
}

.crown-animation {
    position: absolute; /* Keep this */
    top: -40px; /* Adjust this value to position the crown correctly */
    left: 52%; /* Center the crown horizontally */
    transform: translateX(-50%); /* Center the crown */
    animation: spin-left-right 2s infinite; /* Adjust duration as needed */
    width: 80px; /* Adjust width if needed */
    height: auto; /* Maintain aspect ratio */
}


@keyframes spin-left-right {
    0% {
        transform: translateX(-50%) rotate(0deg);
    }
    25% {
        transform: translateX(-50%) rotate(-15deg);
    }
    50% {
        transform: translateX(-50%) rotate(0deg);
    }
    75% {
        transform: translateX(-50%) rotate(15deg);
    }
    100% {
        transform: translateX(-50%) rotate(0deg);
    }
}

