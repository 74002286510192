/* Flexbox Layout to push footer to the bottom */
body, html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #000, #333);
  color: #fff;
  overflow-x: hidden;
}

main {
  flex-grow: 1; /* This ensures the main content takes up available space */
  width: 100%;
}

.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
}

/* Navbar Styles */
.navbar {
  background-color: #333;
  padding: 15px;
  color: #fff;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.logo-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.logo:hover {
  text-decoration: underline;
}

.navbar .nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar .nav-links li {
  display: inline-block;
  margin-left: 20px;
}

.navbar .nav-links li a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.navbar .nav-links li a:hover {
  text-decoration: underline;
}

/* Responsive Navbar */
@media (max-width: 768px) {
  .navbar .container {
      flex-direction: column;
      align-items: flex-start;
  }
  .navbar .nav-links {
      margin-top: 10px;
  }
}

/* Particle background setup */
#particles-js {
  position: absolute; /* Change to absolute to avoid scrolling issues */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  overflow: hidden; /* Prevent overflow */
}

/* Snowflake particle styles */
.floating-particle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url('./images/snowflakes.png'); /* Ensure this path is correct */
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.8;
  animation: float 30s linear infinite;
}

@keyframes float {
  0% {
      transform: translateY(0) translateX(0) scale(1);
      opacity: 0.8;
  }
  50% {
      transform: translateY(-200px) translateX(100px) scale(1.2);
      opacity: 0.5;
  }
  100% {
      transform: translateY(300px) translateX(-100px) scale(0.8);
      opacity: 0.2;
  }
}


/* Footer Styles */
footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding-bottom: 20px;
}

.footer-section {
  flex-basis: 45%;
}

.footer-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.social-icons {
  list-style: none;
  padding: 25px;
  display: flex;
  gap: 15px;
  justify-content: center;
  position: relative;
  bottom: 25px;
  left: 0px;
}

.social-icons li {
  display: inline-block;
}

.social-icons img {
  width: 30px;
  height: auto;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin: 5px 0;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-bottom {
  border-top: 1px solid #555;
  padding-top: 10px;
}

.footer-bottom a {
  color: #4a90e2;
  text-decoration: none;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

.coming-soon {
  position: relative;
  pointer-events: none; /* Make it non-clickable */
}

.coming-soon .overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, rgba(255, 0, 0, 0.8), rgba(128, 128, 128, 0.8)); /* Red to grey gradient */
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  z-index: 2;
  opacity: 0.9; /* Adjusted for better visibility */
  pointer-events: none; /* Prevent interaction with the overlay */
  
  /* Create a cool shape using clip-path */
  clip-path: polygon(0 0, 100% 0, 100% 60%, 50% 100%, 0 60%);
}

.coming-soon img.big-chicken-logo {
  filter: grayscale(100%); /* Make the image appear grayed out */
  opacity: 0.5;
}

/* Leaderboard Overview Section */
/* Leaderboard Overview Section */
/* Leaderboard Overview Section */
/* Leaderboard Overview Section */
.leaderboard-overview {
  text-align: center;
  padding: 50px 0;
}

.leaderboard-overview h2 {
  font-size: 36px;
  color: #fff;
  margin-bottom: 20px;
}

.leaderboard-score {
  display: flex;
  justify-content: space-around; /* Closer alignment */
  align-items: center;
  width: 70%; /* Reduce width to bring sections closer */
  margin: 0 auto;
}

/* Score containers */
.left-score, .middle-score, .right-score {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.big-chicken-logo {
  width: 100%;
  max-width: 250px;
  height: auto;
  margin-bottom: 10px;
}

.score-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.coin-icon {
  width: 35px;
  height: 35px;
  margin-right: 5px; /* Reduce margin between coin and score */
  vertical-align: middle;
}

.score-text {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  vertical-align: middle;
}

/* Dividers */
.divider-left, .divider-middle, .divider-right {
  width: 2px;
  height: 100px;
  background: linear-gradient(to bottom, #888, #fff);
}

.divider-left {
  margin-right: 10px; /* Reduce margin between sections */
}

.divider-middle {
  margin-left: 10px; /* Bring sections closer */
  margin-right: 10px;
}

.divider-right {
  margin-left: 10px;
}

/* Game Cards Section */
.game-cards {
  padding: 50px 0;
}

.game-cards h2 {
  padding-left: 720px;
  color: #fff;
  font-size: 36px;
  margin-bottom: 45px;
  text-align: left;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
  padding: 0;
  margin: 0;
}

.card {
  background: linear-gradient(to bottom, rgba(128, 128, 128, 0.7), rgba(0, 0, 0, 0.7));
  width: calc(25% - 15px);
  margin: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  position: relative;
}

@media (max-width: 1024px) {
  .card {
      width: calc(33.33% - 15px);
  }
}

@media (max-width: 768px) {
  .cards-container {
      flex-direction: column;
  }
  .card {
      width: 100%;
  }
}

.card img {
  width: 100%;
  height: 130px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.card-content {
  padding: 20px;
  padding-top: 160px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.card-content h3 {
  margin-top: 0;
  font-size: 22px;
  text-align: center;
}

.card-content p {
  color: #ddd;
  font-size: 14px;
  text-align: center;
}

.gradient-text {
  font-size: 35px;
  font-weight: bold;
  background: linear-gradient(90deg, #4a90e2, #7b61ff, #b388ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 2px solid;
  border-image: linear-gradient(90deg, #4a90e2, #7b61ff, #b388ff) 1;
  padding: 5px;
  display: inline-block;
  text-align: center;
  margin-top: -10px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px 2px rgba(74, 144, 226, 0.7), 0 0 15px 4px rgba(123, 97, 255, 0.5);
  border: 2px solid transparent;
  border-image: linear-gradient(90deg, #4a90e2, #7b61ff, #b388ff) 1;
}

.play-button {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 80px;
  background-color: rgb(49, 121, 255);
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.play-button:hover {
  background-color: rgb(70, 70, 70);
}

/* Social Media Cards Section */
.social-media-cards {
  padding: 50px 0;
  text-align: center;
}

.social-media-cards h2 {
  font-size: 36px;
  color: #fff;
  margin-bottom: 30px;
}

.social-media-cards .cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.social-media-cards .card {
  background: rgba(255, 255, 255, 0.1);
  width: 250px;
  margin: 10px;
  padding: 30px 15px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social-media-cards .card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.social-media-cards .card img {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.social-media-cards .card h3 {
  font-size: 20px;
  color: #fff;
  margin-top: 50px;
}

.social-media-cards .card p {
  font-size: 14px;
  color: #ddd;
  margin-bottom: 15px;
}

.social-media-cards .play-button {
  padding: 10px 20px;
  background-color: rgb(49, 121, 255);
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.social-media-cards .play-button:hover {
  background-color: rgb(70, 70, 70);
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 600px) {
  .game-cards h2, .social-media-cards h2, .leaderboard-overview h2 {
      padding-left: 0;
      text-align: center;
  }
  .cards-container {
      flex-direction: column;
      align-items: center;
  }
  .card {
      width: 90%;
      margin-bottom: 20px;
  }
}

/* Leaderboard Styles */
.leaderboard-section {
  padding: 50px 0;
  text-align: center;
}

.leaderboard-section h1 {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.countdown-timer {
  font-size: 18px;
  margin-bottom: 20px;
  color: #555;
}

/* Podium Styles */
.podium-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 20px 0;
  gap: 20px;
  padding: 20px;
}

.podium-spot {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
  position: relative;
  transition: transform 0.3s;
  text-align: center;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

/* Hover effect */
.podium-spot:hover {
  transform: scale(1.05);
}

/* First place styling */
.podium-spot.gold {
  background: rgba(255, 215, 0, 0.1);
  width: 180px;
  min-height: 240px;
}

/* Second place styling */
.podium-spot.silver {
  background: rgba(192, 192, 192, 0.1);
  width: 150px;
  min-height: 200px;
}

/* Third place styling */
.podium-spot.bronze {
  background: rgba(205, 127, 50, 0.1);
  width: 150px;
  min-height: 200px;
}

/* Podium content styles */
.podium-rank {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

.podium-username,
.podium-wagered,
.podium-reward {
  font-size: 18px;
  margin: 5px 0;
  color: #fff;
}

/* Reward image styling */
.reward-image {
  width: 80px;
  height: 80px;
  margin: 0 auto 15px auto;
  object-fit: contain;
}


/* Leaderboard Table Styles */
.leaderboard-table {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-collapse: collapse;
}

.leaderboard-header,
.leaderboard-row {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.leaderboard-header {
  background-color: #f4f4f4;
  font-weight: bold;
}

.leaderboard-row:nth-child(even) {
  background-color: #fafafa;
}

.leaderboard-row .rank,
.leaderboard-header .rank {
  width: 10%;
}

.leaderboard-row .username,
.leaderboard-header .username {
  width: 40%;
}

.leaderboard-row .wagered,
.leaderboard-header .wagered {
  width: 25%;
}

.leaderboard-row .reward,
.leaderboard-header .reward {
  width: 25%;
}

.leaderboard-row .rank {
  font-size: 20px; /* Slightly larger font for rank */
  font-weight: bold;
}

.leaderboard-row .username,
.leaderboard-row .wagered,
.leaderboard-row .reward {
  font-size: 16px; /* Consistent font size for row data */
  color: #555; /* Slightly darker for better visibility */
}